<template>
  <div class="owner-patients-information">
    <div class="page-title">
      <base-page-title>{{
        $t('ownerPatientsInformation.pageTitle')
      }}</base-page-title>
    </div>
    <div class="information-list-owner">
      <information-list-owner
        :owner-data="owner"
        :patient-list="patientsForDisplay"
      />
    </div>
    <div class="change-button">
      <base-decision-button @click="pushToOwnerPatientsInformationChange">{{
        $t('common.buttonChange')
      }}</base-decision-button>
    </div>
    <div class="back-button">
      <base-cancel-button @click="backReservationMenu">{{
        $t('common.buttonReturn')
      }}</base-cancel-button>
    </div>
  </div>
</template>

<script>
import BasePageTitle from '@/components/parts/atoms/BasePageTitle.vue'
import InformationListOwner from '@/components/parts/organisms/InformationListOwner.vue'
import BaseDecisionButton from '@/components/parts/atoms/BaseDecisionButton.vue'
import BaseCancelButton from '@/components/parts/atoms/BaseCancelButton.vue'

export default {
  name: 'OwnerPatientsInformation',

  components: {
    BasePageTitle,
    InformationListOwner,
    BaseDecisionButton,
    BaseCancelButton
  },

  computed: {
    owner() {
      return this.$store.getters['owner/getOwner']
    },
    patients() {
      return this.$store.getters['patient/getPatients']
    },
    species() {
      return this.$store.getters['species/getDataIncludeDelData']
    },
    patientsForDisplay() {
      const patientsForDisplay = this.patients.map(patient => {
        const species = this.species.find(v => v.id === patient.speciesId)
        const speciesId = species
          ? this.$i18n.locale === 'en'
            ? species.nameEnglish || species.name
            : species.name
          : ''
        return { ...patient, speciesId }
      })
      return patientsForDisplay
    }
  },

  created() {
    this.$store.dispatch('owner/setChangeOwner')
    this.$store.dispatch('patient/setChangePatients')
    this.$store.dispatch('patient/setDelPatientsIds')
  },

  methods: {
    pushToOwnerPatientsInformationChange() {
      this.$router.push({ name: 'OwnerPatientsInformationChange' })
    },
    backReservationMenu() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
.owner-patients-information {
  margin-bottom: 100px;
  > .page-title {
    margin-top: 69px;
  }
  > .information-list-owner {
    margin-top: 106px;
  }
  > .change-button {
    margin-top: 84px;
  }
  > .back-button {
    margin-top: 23px;
  }
}
@media (max-width: $global-media-width) {
  .owner-patients-information {
    > .page-title {
      padding: 0 10px;
    }
    > .information-list-owner {
      margin-top: 54px;
    }
    > .change-button {
      margin-top: 115px;
    }
  }
}
</style>
