<template>
  <div class="information-list-owner">
    <information-list-item>
      <template #title>{{ $t('common.labelName') }}</template>
      <template #content>{{
        $t('common.ownerName', {
          first: ownerData.firstName,
          last: ownerData.lastName,
          honorific: ''
        })
      }}</template>
    </information-list-item>
    <information-list-item>
      <template #title>{{ $t('common.labelKatakana') }}</template>
      <template #content>{{ ownerNameKana }}</template>
    </information-list-item>
    <information-list-item>
      <template #title>{{ $t('common.labelPetName') }}</template>
      <template #content
        ><information-list-item-patient :patient-list="patientList"
      /></template>
    </information-list-item>
    <information-list-item>
      <template #title>{{ $t('common.labelPhoneNumber') }}</template>
      <template #content>{{ ownerData.tel }}</template>
    </information-list-item>
    <information-list-item>
      <template #title>{{ $t('common.labelEmail') }}</template>
      <template #content>{{ ownerData.email }}</template>
    </information-list-item>
    <information-list-item>
      <template #title>{{ $t('common.labelAddress') }}</template>
      <template #content>{{ fullAddress }}</template>
    </information-list-item>
  </div>
</template>

<script>
import InformationListItem from '@/components/parts/molecules/InformationListItem'
import InformationListItemPatient from '@/components/parts/atoms/InformationListItemPatient.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'InformationListOwner',

  components: {
    InformationListItemPatient,
    InformationListItem
  },

  props: {
    ownerData: { type: Object },
    patientList: { type: Array }
  },

  computed: {
    ...mapGetters({
      getMasterDatum: 'master/getDataById'
    }),
    ownerNameKana() {
      const { lastNameKana, firstNameKana } = this.ownerData
      return `${lastNameKana}${'　'}${firstNameKana}`
    },
    fullAddress() {
      let address = ''
      if (this.ownerData.postalCode) {
        address += `${this.ownerData.postalCode}\n`
      }
      if (this.ownerData.prefectureId) {
        address += this.getMasterDatum(
          'prefectures',
          this.ownerData.prefectureId
        ).name
      }
      if (this.ownerData.address) {
        address += this.ownerData.address
      }
      if (this.ownerData.building) {
        address += this.ownerData.building
      }
      return address
    }
  }
}
</script>
